import React, { useState, useEffect, useRef } from "react";
import {
  CloseIcon,
  DownloadIcon,
  ArrowLeft,
  ArrowRight,
} from "@icarius-icons";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import Swiper from "react-id-swiper";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import DialogTransition from "@icarius-common/dialogTransition";
import Confetti from 'react-confetti'
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector, useDispatch } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { setModalIsVisited } from "@icarius-pages/home/actions";
import { getModalisVisited } from "@icarius-pages/home/selectors";

const NewsDialog = (props) => {

  const {
    data,
    handleClick,
  } = props;

  const dispatch = useDispatch();
  const swiper = useRef();
  const color = useSelector(getAppColor);
  const modalIsVisited = useSelector(getModalisVisited);

  const [showNewsModal, setShowNewsModal] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // abrir modal
  useEffect(() => {
    let timeout;

    if (data.length > 0 && !modalIsVisited) {
      timeout = setTimeout(() => {
        setShowNewsModal(true);
        dispatch(setModalIsVisited());
      }, 2650);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [dispatch, data, modalIsVisited])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const element = document.getElementsByClassName('swiper-container')[0];
      if (element) {
        setWidth(element.offsetWidth);
        setHeight(element.offsetHeight);
      }
    }

    setTimeout(() => updateWindowDimensions(), 500);
    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [])

  const params = {
    on: {
      slideChangeTransitionStart: () => {
        if (swiper.current) {
          setActiveSlideIndex(swiper.current.activeIndex);
        }
      }
    },
  };

  const goNext = () => {
    if (swiper.current) swiper.current.slideNext();
  };

  const goPrev = () => {
    if (swiper.current) swiper.current.slidePrev();
  };

  const handleClose = () => setShowNewsModal(false);

  return (
    <Dialog
      open={showNewsModal}
      TransitionComponent={DialogTransition}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <div style={{ paddingTop: 5, textAlign: "center" }}>
          <Typography className={"whiteText"} style={{ fontSize: 18 }}>
            {`${activeSlideIndex + 1}/${data.length}`}
          </Typography>
        </div>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      </DialogTitle>
      <div className={"dialog-container"}>
        <DialogContent style={{ paddingTop: 0 }}>
          {
            activeSlideIndex !== 0 &&
            <IconButton className="custom-swiper-button custom-swiper-button-left" onClick={goPrev}>
              <ArrowLeft className={"swiper-button-icon"} style={{ color: color }} />
            </IconButton>
          }
          {
            activeSlideIndex !== (data.length - 1) &&
            <IconButton className="custom-swiper-button custom-swiper-button-right" onClick={goNext}>
              <ArrowRight className={"swiper-button-icon"} style={{ color: color }} />
            </IconButton>
          }
          <Swiper
            {...params}
            ref={(node) => {
              if (node) swiper.current = node.swiper;
            }}
          >
            {
              data.map((item) => {
                return (
                  <div key={item.code}>
                    {
                      item.hasConfetti &&
                      <Confetti
                        width={width - 80}
                        height={height}
                        style={{ left: 40 }}
                      />
                    }
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 20,
                        paddingBottom: 10,
                      }}
                    >
                      {
                        Boolean(item.employeeImage) &&
                        <img
                          style={{ maxWidth: 64, maxHeight: 64, borderRadius: '50%', margin: '0 auto' }}
                          src={IMAGES_ENDPOINT + item.employeeImage}
                          alt={"imageNotFound"}
                        />
                      }
                      <div style={{ width: "100%", textAlign: "center", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        <Typography className={"whiteText"} style={{ fontSize: 24, fontWeight: 600 }}>
                          {item.text}
                        </Typography>
                      </div>
                    </div>
                    {
                      Boolean(item.attachment) &&
                      <div style={{ paddingBottom: 10 }}>
                        <div
                          style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}
                          onClick={() => handleClick(item)}
                        >
                          {
                            !item.attachmentIsLink &&
                            <DownloadIcon className={"download-icon"} style={{ fill: 'var(--icons)', marginRight: 20 }} />
                          }
                          <Typography
                            className="whiteText"
                            style={{
                              cursor: "pointer",
                              fontSize: 18,
                              fontWeight: 400,
                              textDecoration: "underline",
                              textAlign: "center",
                              wordBreak: "break-word",
                              maxWidth: "80%"
                            }}>
                            {item.attachmentIsLink ? item.attachment : getLocalizedString("downloadAttachment")}
                          </Typography>
                        </div>
                      </div>
                    }
                    <div style={{ width: "100%", height: 400, display: 'flex' }}>
                      <img
                        className={"imageModalPGO"}
                        style={item.attachment && { cursor: "pointer" }}
                        onClick={() => handleClick(item)}
                        alt={item.text}
                        src={item.image ? IMAGES_ENDPOINT + item.image : ""}
                      />
                    </div>
                  </div>
                )
              })
            }
          </Swiper>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default NewsDialog;