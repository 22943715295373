import React from "react";
import CountUp from 'react-countup';

const Counter = ({ number }) => (
  <CountUp
    start={0}
    end={number}
    delay={0.5}
    duration={2}
    separator="."
    className={"counter-number"}
  />
)

export default Counter;
