import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import * as actionTypes from "./actionTypes";
import { getHomeDataAPI } from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";

export const setModalIsVisited = () => async (dispatch) => {
  dispatch({ type: actionTypes.MODAL_IS_VISITED });
};

export const setModalIsNotVisited = () => async (dispatch) => {
  dispatch({ type: actionTypes.MODAL_IS_NOT_VISITED });
};

export const getHomeDataAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getHomeDataAPI();

    let counters = response.data?.counters;
    let downloads = response.data?.downloads;
    let links = response.data?.links;
    let modal = response.data?.modal;
    let news = response.data?.news;
    let polls = response.data?.polls;
    let status = response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        counters,
        downloads,
        links,
        modal,
        news,
        polls,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};