import React from "react";
import { Typography, Card, Tooltip } from "@material-ui/core";
import FabButton from "@icarius-common/fabButton";
import { getLocalizedString } from "@icarius-localization/strings";
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import { MedalTableIcon } from "@icarius-icons/index";

const CARD_HEIGHT = 433;
const IMAGE_HEIGHT = 175;

const PollItem = (props) => {

  const {
    name,
    image,
    text,
    points,
    isCompleted,
    isDesktopVersion,
    onClick,
  } = props;

  return (
    <Card
      style={{
        height: CARD_HEIGHT,
        borderRadius: 15,
        backgroundColor: "var(--drawer)",
        boxShadow: !isDesktopVersion && 'none',
      }}
    >
      <div
        className={"item-container"}
        style={{ cursor: "cursor" }}
        onClick={onClick}
      >
        <div
          className={"image-container"}
          style={{
            backgroundImage: "url('" + image + "')",
            backgroundSize: "cover",
            height: `${IMAGE_HEIGHT}px`
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className={"download-icon-container"}>
              {
                isCompleted ?
                  <CheckIcon className={"download-icon"} />
                  :
                  <CreateIcon className={"download-icon"} />
              }
            </div>
            <Typography
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: 20,
                fontWeight: 400,
                textDecoration: "underline",
                textAlign: "center",
                wordBreak: "break-word",
                maxWidth: "80%"
              }}>
              {isCompleted ? 'Encuesta respondida' : 'Déjanos tu opinión'}
            </Typography>
          </div>
        </div>
        <div
          style={{
            height: `calc(${CARD_HEIGHT}px - ${IMAGE_HEIGHT}px - 40px)`,
            margin: "20px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 700, marginBottom: 10 }} align='center'>
            {name}
          </Typography>
          <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 500, marginBottom: 20 }} align='center'>
            {text}
          </Typography>
          {
            isCompleted === false &&
            <FabButton ignoreIcon useColorBackground title={getLocalizedString("answerPoll")} />
          }
          {
            Boolean(points) &&
            <Tooltip arrow title={`Quienes respondan esta encuesta recibirán ${points} puntos en su medallero`}>
              <div
                style={{
                  position: 'absolute',
                  bottom: -15,
                  left: 'calc(50% - 15px)',
                }}
              >
                <MedalTableIcon />
              </div>
            </Tooltip>
          }
        </div>
      </div>
    </Card>
  )
};

export default PollItem;