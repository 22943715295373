import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { previewFromURL } from "@icarius-utils/download";
import { openPollAction } from "@icarius-common/poll/actions";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import {
  getCounters,
  getDownloads,
  getLinks,
  getNews,
  getModal,
  getPolls,
  getIsLoading,
} from "../selectors";
import { getHomeDataAction } from "../actions";
import { getAuthInfo } from "@icarius-pages/login/sessionStorage";

const useHome = () => {

  const dispatch = useDispatch();

  const { token } = getAuthInfo(); // se trae esto para triggerear el get cuando se cambie de sociedad (estando en esta pantalla)

  useEffect(() => {
    dispatch(getHomeDataAction());
  }, [dispatch, token])

  const isLoading = useSelector(getIsLoading);
  const counters = useSelector(getCounters);
  const downloads = useSelector(getDownloads);
  const links = useSelector(getLinks);
  const modal = useSelector(getModal);
  const news = useSelector(getNews);
  const polls = useSelector(getPolls);

  const hasContent = useMemo(() => {
    return [news, counters, links, downloads, polls].some((items) => items.length > 0);
  }, [news, counters, links, downloads, polls])

  const handleItemClick = (item) => {
    if (!item.attachment) return;
    if (item.attachment.includes('http')) {
      window.open(item.attachment, "_blank");
      return;
    }
    previewFromURL(RESOURCES_ENDPOINT + item.attachment);
  };

  const handlePollClick = (item) => {
    dispatch(openPollAction(item.code, item.text, item.type));
  };

  const handlers = {
    handleItemClick,
    handlePollClick,
  }

  return {
    isLoading,
    counters,
    downloads,
    links,
    modal,
    news,
    polls,
    hasContent,
    handlers,
  }
}

export default useHome;