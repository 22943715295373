import React from "react";
import Counter from "../counter";
import { Typography, Card, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import _ from "@lodash";

const CARD_HEIGHT = 200;

const CounterItem = (props) => {

  const {
    text,
    image,
    attachment,
    attachmentIsLink,
    number,
    onClick,
  } = props;

  const useSmallSize = useMediaQuery(`(max-width:650px)`);
  const containerStyle = useSmallSize ? { margin: "15px", display: 'flex', flexDirection: 'column', alignItems: 'center' } : { margin: "15px" };
  const emptyStyle = { height: `${CARD_HEIGHT}px`, backgroundColor: "transparent", border: 0 };

  return (
    <Card
      style={emptyStyle}
      onClick={text ? onClick : null}
    >
      <div style={containerStyle}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {
            Boolean(image) &&
            <img
              src={image}
              alt="img"
              style={{
                objectFit: "cover",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
                marginRight: 15
              }}
            />
          }
          {
            Boolean(number) &&
            <div className={"display-number-container"} style={{ marginLeft: image ? 0 : 15 }}>
              <Counter
                key={text}
                number={number}
              />
            </div>
          }
        </div>
        <div
          style={{
            height: `calc(${CARD_HEIGHT}px - ${CARD_HEIGHT / 2}px - 20px)`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {
            Boolean(text) &&
            <Typography className={"item-text"} style={{ textAlign: "left", fontSize: 14, fontWeight: 500 }}>
              {_.truncate(text.replace(/<(?:.|\n)*?>/gm, ""), { length: 70 })}
            </Typography>
          }
          {
            Boolean(attachment) &&
            <div
              style={{
                position: "absolute",
                bottom: "5px",
              }}
            >
              <Typography
                className="whiteText"
                style={{
                  marginBottom: 15,
                  cursor: "pointer",
                  fontSize: 14,
                  fontWeight: 700,
                  textDecoration: "underline",
                  textAlign: "left"
                }}
              >
                {attachmentIsLink ? _.truncate(attachment.replace(/<(?:.|\n)*?>/gm, ""), { length: 25 }) : getLocalizedString("downloadAttachment")}
              </Typography>
            </div>
          }
        </div>
      </div>
    </Card>
  )
};

export default CounterItem;