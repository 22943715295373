import React from "react";
import { Typography, Divider, useMediaQuery } from "@material-ui/core";
import { DownloadIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import Confetti from 'react-confetti'
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import _ from "@lodash";
import ProfileImg from "@icarius-common/profileImg";

const sizes = [
  { width: 863.5, height: 474 },
  { width: 536, height: 325 },
  { width: 504, height: 325 },
  { width: 311, height: 243 },
];

const NewsItem = (props) => {

  const {
    text,
    image,
    attachment,
    attachmentIsLink,
    hasConfetti,
    employeeImage,
    onClick,
  } = props;

  const color = useSelector(getAppColor);

  const isMoreOrEqualThan1920 = useMediaQuery(`(min-width:1920px)`);
  const isMoreOrEqualThan1280 = useMediaQuery(`(min-width:1280px)`);
  const isMoreOrEqualThan834 = useMediaQuery(`(min-width:834px)`);

  const getSize = () => {
    if (isMoreOrEqualThan1920) return sizes[0];
    if (isMoreOrEqualThan1280) return sizes[1];
    if (isMoreOrEqualThan834) return sizes[2];
    return sizes[3];
  }

  if (isMoreOrEqualThan834) {
    return (
      <div
        onClick={attachment ? onClick : null}
        style={{
          height: getSize().height,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {hasConfetti && <Confetti />}
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: '2px'
          }}
        >
          <div className="newsTitleContainer" style={{ zIndex: 2 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                Boolean(employeeImage) &&
                <ProfileImg
                  image={employeeImage}
                  size={90}
                />
              }
              <Typography className={"newsTitle whiteText"} style={{ marginLeft: 20 }}>
                {text}
              </Typography>
            </div>
            {
              Boolean(image) &&
              <Divider style={{ margin: "15px 0px", background: color, height: 2 }} />
            }
            {
              Boolean(attachment) &&
              <div>
                <div style={{ display: "flex" }}>
                  {
                    !attachmentIsLink &&
                    <div className={"download-icon-container"}>
                      <DownloadIcon className={"download-icon"} style={{ color: 'var(--mainText)' }} />
                    </div>
                  }
                  <Typography
                    className="whiteText"
                    style={{
                      cursor: "pointer",
                      fontSize: 20,
                      fontWeight: 400,
                      textDecoration: "underline",
                      textAlign: "center",
                      wordBreak: "break-word",
                      maxWidth: "80%",
                      marginBottom: 5,
                      marginLeft: 10,
                    }}
                  >
                    {attachmentIsLink ? _.truncate(attachment.replace(/<(?:.|\n)*?>/gm, ""), { length: 40 }) : getLocalizedString("downloadAttachment")}
                  </Typography>
                </div>
              </div>
            }
          </div>
          {
            Boolean(image) &&
            <>
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <div
                  style={{
                    overflow: 'hidden',
                    width: getSize().width,
                    height: getSize().height,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: "0px",
                      background: 'linear-gradient(90deg, var(--mainBackgroundColor) 0%, transparent 15%)',
                    }}
                  />
                  <img
                    src={image}
                    alt=""
                    style={{
                      width: getSize().width,
                      height: 'auto',
                      borderRadius: '10px',
                    }}
                  />
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  };

  return (
    <div onClick={attachment ? onClick : null}>
      {hasConfetti && <Confetti />}
      {
        Boolean(image) &&
        <>
          <div style={{ display: 'flex', padding: "0px 10px", justifyContent: 'flex-end', flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  overflow: 'hidden',
                  width: getSize().width,
                  height: getSize().height,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0px",
                    background: 'linear-gradient(90deg, var(--mainBackgroundColor) 0%, transparent 15%)',
                  }}
                />
                <img
                  src={image}
                  alt=""
                  style={{
                    width: getSize().width,
                    height: 'auto',
                    borderRadius: '10px',
                  }}
                />
              </div>
            </div>
            <div className="newsTitleContainer" style={{ zIndex: 2, position: "initial", width: "100%" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                  Boolean(employeeImage) &&
                  <ProfileImg
                    image={employeeImage}
                    size={60}
                  />
                }
                <Typography className={"newsTitle whiteText"} style={{ marginLeft: 20 }}>
                  {text}
                </Typography>
              </div>
            </div>
            {
              Boolean(attachment) &&
              <div style={{ zIndex: 2, width: "100%", display: "flex" }}>
                {
                  !attachmentIsLink &&
                  <div className={"download-icon-container"} style={{ marginRight: 20 }}>
                    <DownloadIcon className={"download-icon"} />
                  </div>
                }
                <Typography
                  className="whiteText"
                  style={{
                    cursor: "pointer",
                    fontSize: 20,
                    fontWeight: 400,
                    textDecoration: "underline",
                    wordBreak: "break-word",
                    maxWidth: "80%"
                  }}
                >
                  {attachmentIsLink ? _.truncate(attachment.replace(/<(?:.|\n)*?>/gm, ""), { length: 40 }) : getLocalizedString("downloadAttachment")}
                </Typography>
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}

export default NewsItem;