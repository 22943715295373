import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { getAppColor, getAvailableMenus } from "src/app/selectors";

const HomeHeaderItem = ({ onClick, isSelected, title }) => {
  const color = useSelector(getAppColor);

  return (
    <div
      onClick={onClick}
      className="tabContainer"
      style={{ borderBottom: `2px solid ${isSelected ? color : 'transparent'}` }}
    >
      <Typography align="center" className="whiteText" variant="h6" style={{ fontSize: 18 }}>
        {title}
      </Typography>
    </div>
  )
}

const HomeHeader = () => {

  const history = useHistory();
  const location = useLocation();
  const availableMenus = useSelector(getAvailableMenus);

  const headerData = [
    {
      title: getLocalizedString("home"),
      path: paths.home,
    },
    {
      title: getLocalizedString("feed"),
      path: paths.feed,
    },
    {
      title: getLocalizedString("myNotes"),
      path: paths.notes,
    },
    {
      title: getLocalizedString("myDesktop"),
      path: paths.myDesktop,
    },
  ];

  const getIsSelected = (path) => {
    if (path === paths.home) {
      //porque si se pone un path que no existe, redirije al home
      return ![paths.notes, paths.myDesktop, paths.feed].includes(location.pathname);
    }
    return location.pathname === path;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        margin: '10px 50px',
      }}
    >
      {
        headerData.map((item) => {
          if (item.path !== paths.home && !availableMenus.includes(item.path.replace('/', ''))) return null;
          return (
            <HomeHeaderItem
              key={item.path}
              title={item.title}
              onClick={() => history.push(item.path)}
              isSelected={getIsSelected(item.path)}
            />
          )
        })
      }
    </div>
  )
}

export default HomeHeader;