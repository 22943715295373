import React from "react";
import { Typography, Card } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DownloadIcon } from "@icarius-icons";
import _ from "@lodash";

const CARD_HEIGHT = 433;
const IMAGE_HEIGHT = 175;

const DownloadLinkItem = (props) => {

  const {
    name,
    text,
    image,
    attachment,
    attachmentIsLink,
    onClick,
  } = props;

  const cardStyle = {
    height: `${CARD_HEIGHT}px`,
    borderRadius: "15px",
    backgroundColor: "var(--drawer)",
    boxShadow: 'none',
  };

  const imageContainerEmptyStyle = {
    cursor: !attachmentIsLink ? "pointer" : "",
    height: `${IMAGE_HEIGHT}px`
  };

  const imageContainerFullStyle = {
    backgroundImage: "url('" + image + "')",
    backgroundSize: "cover",
  };

  return (
    <Card style={cardStyle} onClick={text ? onClick : null}>
      <div className={"item-container"}>
        <div
          className={"image-container"}
          style={{ ...imageContainerEmptyStyle, ...(image ? imageContainerFullStyle : {}) }}
        >
          {
            Boolean(attachment) && !attachmentIsLink &&
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: image ? "rgba(0, 0, 0, 0.5)" : "transparent",
              }}
            >
              <div className={"download-icon-container"}>
                <DownloadIcon className={"download-icon"} />
              </div>
              <Typography
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: 20,
                  fontWeight: 400,
                  textDecoration: "underline",
                  textAlign: "center",
                  wordBreak: "break-word",
                  maxWidth: "80%"
                }}
              >
                {getLocalizedString("downloadAttachment")}
              </Typography>
            </div>
          }
        </div>
        <div
          style={{
            height: `calc(${CARD_HEIGHT}px - ${IMAGE_HEIGHT}px - 40px)`,
            margin: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {
            Boolean(attachment) && attachmentIsLink &&
            <Typography
              style={{
                marginBottom: 10,
                cursor: "pointer",
                fontSize: 14,
                fontWeight: 700,
                textDecoration: "underline",
                textAlign: "center"
              }}
            >
              {_.truncate(attachment.replace(/<(?:.|\n)*?>/gm, ""), { length: 40 })}
            </Typography>
          }
          <Typography className={"item-name"}>
            {name}
          </Typography>
          <Typography className={"item-text"}>
            {text}
          </Typography>
        </div>
      </div>
    </Card>
  )
};

export default DownloadLinkItem;