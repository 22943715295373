import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  modalIsVisited: false,
  counters: [],
  downloads: [],
  links: [],
  modal: [],
  news: [],
  polls: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        counters: action.payload.counters,
        downloads: action.payload.downloads,
        links: action.payload.links,
        modal: action.payload.modal,
        news: action.payload.news,
        polls: action.payload.polls,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE_POLLS:
      return {
        ...state,
        polls: action.payload.polls,
      };

    case actionTypes.MODAL_IS_VISITED:
      return { ...state, modalIsVisited: true };
    case actionTypes.MODAL_IS_NOT_VISITED:
      return { ...state, modalIsVisited: false };

    default:
      return state;
  }
}
