import React from "react";
import FabButton from "@icarius-common/fabButton";
import { getLocalizedString } from "@icarius-localization/strings";
import { Typography, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import {
    PublicationIcon,
    PublicationIconNumbers,
    PublicationIconImportantNews,
    PublicationIconLinks,
    PublicationIconDownloads
} from "@icarius-icons";
import { XS_DEVICE_WIDTH_BREAKPOINT, LG_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";

const typeItemArr = [
    {
        icon: <PublicationIconNumbers style={{ maxHeight: "25px", width: "100%" }} />,
        title: getLocalizedString("countersSliderTitle"),
    },
    {
        icon: <PublicationIcon style={{ maxHeight: "25px", width: "100%" }} />,
        title: getLocalizedString("news"),
    },
    {
        icon: <PublicationIconImportantNews style={{ maxHeight: "25px", width: "100%" }} />,
        title: "Noticias destacadas",
    },
    {
        icon: <PublicationIconLinks style={{ maxHeight: "25px", width: "100%" }} />,
        title: getLocalizedString("favouriteSliderTitle"),
    },
    {
        icon: <PublicationIconDownloads style={{ maxHeight: "25px", width: "100%" }} />,
        title: getLocalizedString("downloadSliderTitle"),
    },
]

const NoHomeContent = () => {

    const history = useHistory();
    const { level } = useSelector(getUserData);

    const matchesMoreThanLG = useMediaQuery(`(min-width:${LG_DEVICE_WIDTH_BREAKPOINT + 1}px)`);
    const matchesMoreThanXS = useMediaQuery(`(min-width:${XS_DEVICE_WIDTH_BREAKPOINT + 1}px)`);
    const matchesMoreThan400 = useMediaQuery(`(min-width:${401}px)`);

    const handleRedirect = () => {
        history.push(paths.publicationSettings);
    }

    const getPublicationIconSize = () => {
        if (matchesMoreThanLG) return "120px";
        if (matchesMoreThanXS) return "80px";
        if (matchesMoreThan400) return "60px";
        return "50px";
    }

    const getTitleSize = () => {
        if (matchesMoreThanLG) return "38px";
        if (matchesMoreThanXS) return "36px";
        if (matchesMoreThan400) return "24px";
        return "18px";
    }

    const getPadding = () => {
        if (matchesMoreThanLG) return "50px";
        if (matchesMoreThanXS) return "20px";
        if (matchesMoreThan400) return "20px";
        return "10px";
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography className="whiteText" style={{ fontSize: getTitleSize(), textAlign: "center", paddingTop: getPadding() }}>
                {"Portal informativo de ICARIUS"}
            </Typography>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <PublicationIcon style={{ maxHeight: getPublicationIconSize(), textAlign: "center" }} />
            </div>
            <Typography
                className="whiteText"
                style={{ fontSize: "18px", fontWeight: 700, marginLeft: 10, marginRight: 10, marginBottom: 10, textAlign: "center" }}
            >
                {
                    level === "E" ?
                        "Aún no has generado ninguna publicación"
                        : "Aún no se han generado publicaciónes"
                }
            </Typography>
            {
                level === "E" &&
                <>
                    <Typography
                        className="whiteText"
                        style={{ fontSize: "18px", fontWeight: 400, marginLeft: 10, marginRight: 10, marginBottom: 30, textAlign: "center" }}
                    >
                        {"Comparte información con tu equipo para motivarlos"}
                    </Typography>
                    <FabButton
                        ignoreIcon
                        useColorBackground
                        useWhiteTextAlways
                        onClick={handleRedirect}
                        title={getLocalizedString("generatePublications")}
                    />
                    <Typography
                        className="whiteText"
                        style={{ fontSize: "18px", fontWeight: 400, paddingTop: getPadding(), marginBottom: 10, textAlign: "center" }}
                    >
                        {"Puedes publicar"}
                    </Typography>
                    <div
                        style={{
                            width: "90%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >
                        {
                            typeItemArr.map((item) => {
                                return (
                                    <div
                                        key={item.title}
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                            borderRadius: "10px",
                                            backgroundColor: "var(--drawer)",
                                            margin: "5px",
                                            padding: "20px",
                                            height: "50px",
                                        }}
                                    >
                                        <div style={{ marginRight: 10, display: "flex" }}>
                                            {item.icon}
                                        </div>
                                        <Typography className="whiteText">
                                            {item.title}
                                        </Typography>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default NoHomeContent;
