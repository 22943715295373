import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.holidayTable],
        config: {
            headerName: "Tipo de tabla",
            field: "type",
            isObligatory: true,
            isSelect: true,
        }
    },
    {
        pages: [paths.holidayTable],
        config: {
            headerName: "Antiguedad desde (Años)",
            field: "seniorityFrom",
            isNumeric: true,
            isObligatory: true,
            dataType: "number",
            maxLength: 9,
            minLength: 1,
        }
    },
    {
        pages: [paths.holidayTable],
        config: {
            headerName: "Antiguedad hasta (Años)",
            field: "seniorityTo",
            isNumeric: true,
            isObligatory: true,
            dataType: "number",
            maxLength: 9,
            minLength: 1,
        }
    },
    {
        pages: [paths.holidayTable],
        config: {
            headerName: "Días de derecho",
            field: "lawDays",
            isNumeric: true,
            isObligatory: true,
            dataType: "number",
            maxLength: 5,
            minLength: 1,
        }
    },
    {
        pages: [paths.holidayTable],
        config: {
            headerName: "Días adicionales o progresivos",
            field: "additionalDays",
            isNumeric: true,
            isObligatory: true,
            dataType: "number",
            maxLength: 5,
            minLength: 1,
        }
    },
]