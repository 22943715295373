import React, { useEffect, useState, useRef } from "react";
import Swiper from "react-id-swiper";
import { ArrowLeft, ArrowRight } from "@icarius-icons";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { Divider, Typography, IconButton } from "@material-ui/core";
import CounterItem from "./items/counterItem";
import LinkDownloadItem from "./items/linkDownloadItem";
import PollItem from "./items/pollItem";
import NewsItem from "./items/newsItem";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const slidersPerView = 4;

const newsSwiperParams = {
  slidesPerView: 1,
  centeredSlides: true,
  grabCursor: true,
  containerClass: "main-content-swiper-container",
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  rebuildOnUpdate: true,
};

const genericSwiperParams = {
  grabCursor: true,
  rebuildOnUpdate: true,
  containerClass: "main-content-swiper-container",
  slidesPerView: slidersPerView,
  spaceBetween: 20,
  breakpoints: {
    1500: {
      slidesPerView: slidersPerView - 1,
      spaceBetween: 20,
    },
    1100: {
      slidesPerView: slidersPerView - 1,
      spaceBetween: 20,
    },
    820: {
      slidesPerView: slidersPerView - 2,
      spaceBetween: 10,
    },
    650: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },
};


const useFixWidth = (items) => {
  // cuando carga items que fuerce un rerender para que el swiper no se rompa con el ancho
  const [, setCount] = useState(0);
  useEffect(() => {
    if (items) {
      setCount(prev => prev + 1)
    }
  }, [items])
}

const Slider = (props) => {

  const {
    title,
    items,
    type,
    isLast,
    handleItemClick,
  } = props;

  useFixWidth(items);
  const color = useSelector(getAppColor);
  const swiper = useRef(null);

  const goNext = () => {
    if (swiper.current) swiper.current.slideNext();
  };

  const goPrev = () => {
    if (swiper.current) swiper.current.slidePrev();
  };

  const getTypeToRender = (type, item) => {
    /*
      C = Contador
      M = Noticias
      L = Descargas
      U = Links
      E = Encuestas
    */

    switch (type) {
      case "C":
        return (
          <CounterItem
            text={item.text}
            image={item.image ? IMAGES_ENDPOINT + item.image : null}
            attachment={item.attachment}
            attachmentIsLink={item.attachmentIsLink}
            number={item.number}
            onClick={() => handleItemClick(item)}
          />
        )
      case "E":
        return (
          <PollItem
            name={item.name}
            text={item.text}
            image={item.image ? IMAGES_ENDPOINT + item.image : null}
            points={item.points}
            isCompleted={item.isCompleted}
            onClick={() => handleItemClick(item)}
          />
        )
      case "L":
      case "U":
        return (
          <LinkDownloadItem
            text={item.text}
            name={item.name}
            attachment={item.attachment}
            attachmentIsLink={item.attachmentIsLink}
            image={item.image ? IMAGES_ENDPOINT + item.image : null}
            onClick={() => handleItemClick(item)}
          />
        )
      case "M":
        return (
          <NewsItem
            text={item.text}
            image={item.image ? IMAGES_ENDPOINT + item.image : null}
            attachment={item.attachment}
            attachmentIsLink={item.attachmentIsLink}
            hasConfetti={item.hasConfetti}
            employeeImage={item.employeeImage}
            onClick={() => handleItemClick(item)}
          />
        )
      default:
        return null;
    }
  }

  const paramsToUse = type === "M" ? newsSwiperParams : genericSwiperParams;

  if (!items?.length) return null;

  return (
    <div className={"slider-container"}>
      <IconButton className="custom-swiper-button custom-swiper-button-left" onClick={goPrev}>
        <ArrowLeft className={"swiper-button-icon"} style={{ color: color }} />
      </IconButton>
      <IconButton className="custom-swiper-button custom-swiper-button-right" onClick={goNext}>
        <ArrowRight className={"swiper-button-icon"} style={{ color: color }} />
      </IconButton>
      <Typography className={"title"} style={{ marginLeft: 30, fontWeight: 400 }}>
        {`${title} ${items.length > 0 ? "(" + items.length + ")" : ""}`}
      </Typography>
      <Swiper
        {...paramsToUse}
        ref={node => {
          if (node) swiper.current = node.swiper;
        }}
        className={"swiper-wrapper"}
      >
        {
          (items.length === 0 ? [1, 2, 3, 4, 5] : items).map((item, index) => (
            <div className={"items-slider-container"} key={index}>
              {getTypeToRender(type, item)}
            </div>
          ))
        }
      </Swiper>
      {
        !isLast &&
        <Divider style={{ margin: "15px 30px", background: color, height: 2 }} />
      }
    </div>
  )
}

export default Slider;