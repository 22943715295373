import React from "react";
import CommonPage from "@icarius-common/commonPage";
import HomeHeader from "./homeHeader";
import Content from "./content";
import NoHomeContent from "./noHomeContent";
import NewsDialog from "./newsDialog";
import useHome from "./useHome";

const Main = () => {

  const {
    isLoading,
    counters,
    downloads,
    links,
    modal,
    news,
    polls,
    hasContent,
    handlers,
  } = useHome();

  return (
    <CommonPage
      isLoading={isLoading}
      isNotGridPage
    >
      <HomeHeader />
      <section id={"main-page"} style={{ marginBottom: 50 }}>
        <NewsDialog
          data={modal}
          handleClick={handlers.handleItemClick}
        />
        {
          hasContent ?
            <Content
              counters={counters}
              downloads={downloads}
              links={links}
              news={news}
              polls={polls}
              handleItemClick={handlers.handleItemClick}
              handleItemClickPoll={handlers.handlePollClick}
            />
            :
            (!isLoading && <NoHomeContent />)
        }
      </section>
    </CommonPage>
  )
}

export default Main;